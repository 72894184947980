@import "../../lib.scss";

// breadcrumb

.imhere {
  display: none;
}

.breadcrumb {
  font-size: 16px !important;
  font-family: $font;
  font-weight: normal !important;
  @media screen and (min-width: $b-md) {
    font-size: 18px !important;
    float: right !important;
  }

  a {
    color: $capital-blue !important;
  }

  .sep {
    padding: 0 16px;
  }
}



// listing

.page-company-membership {
  .container-12,
  .container-12 .grid-12.region-content,
  .container-12 .grid-12 {
    max-width: 1200px;
    width: auto;
    @media screen and (min-width: $b-md) {
      width: 1024px;
    }
    @media screen and (min-width: $b-lg) {
      width: 1200px;
    }   
  }
}

.page-company-membership-chartered-building-companies-register #page-title {
  @media screen and (min-width: $b-md) {
    width: 50% !important;
    margin: 32px 0 !important;
  }
}

.view-cbc_companies_search-page .views-exposed-form input, 
.view-cbc_companies_search-page .views-exposed-form .selector {
  border-radius: 0 !important;
  border: solid 1px $capital-blue !important;
  background-color: white !important;
  color: $black !important;
  padding: 12px 18px !important;
  font-family: $font !important;
}

.view-cbc_companies_search-page .views-exposed-form input[type="submit"] {
  background-color: $capital-blue !important;
  color: $white !important;
  padding: 12px 18px !important;
  &:hover {
    background-color: darken($capital-blue, 10%) !important;
  }
}

.view-cbc_companies_search-page a {
  color: $capital-blue !important;
}

.view-cbc_companies_search-page h5 {
  font-family: $heading-font;
  font-size: 1.2rem;
  line-height: 1.2;
  font-weight: $heading-font-bold;
  color: $capital-blue;
  margin-bottom: 1rem;
  @media screen and (min-width: $b-md) {
    font-size: 1.25rem;
  }
}

.view-cbc_companies_search-page .view-content .cbc-company-search__label {
  margin-bottom: 0.5rem !important;
}

.view-cbc_companies_search-page .view-header, 
.view-cbc_companies_search-page .view-header-empty {
  border-bottom: none !important;
  border: none;
}

.view-cbc_companies_search-page .view-content .views-row {
  border-bottom: none !important;
  border-left: 2px solid $capital-blue;
  padding: 8px 0 8px 18px !important;
  margin-bottom: 32px;
}

.view-cbc_companies_search-page .pager {
  font-size: 16px !important;
  font-family: $font;
  font-weight: normal !important;
  line-height: 1.6 !important;
  @media screen and (min-width: $b-md) {
    font-size: 18px !important;
  }

}

.view-cbc_companies_search-page .pager-current {
  background-color: $capital-blue !important;
  color: white;
  font-weight: 500;
}

.view-cbc_companies_search-page .pager .pager-ellipsis,
.view-cbc_companies_search-page .pager .pager-current {
  padding: 0.5em;
}

.view-cbc_companies_search-page .pager li {
  padding: 0;
  a {
    padding: 0.5em;
    display: block;
  }
}

.view-cbc_companies_search-page .pager-first a:hover, 
.view-cbc_companies_search-page .pager-item a:hover, 
.view-cbc_companies_search-page .pager-last a:hover {
  background-color: $capital-blue !important;
  color: white !important;
  border-radius: 0 !important;
}

.view-cbc_companies_search-page .pager-current {
  border-radius: 0 !important;
}

select#edit-field-cbc-grade-tid {
  font-size: 18px;
  line-height: 1.6;
}


// node

.node-cbc-company {
  @media screen and (min-width: $b-md) {
    min-height: 700px;
  }
}

.node-type-cbc-company {


  .container-12,
  .container-12 .grid-12.region-content {
    max-width: 1200px;
    width: auto;
    @media screen and (min-width: $b-md) {
      width: 1024px;
    }
    @media screen and (min-width: $b-lg) {
      width: 1200px;
    }  
  }
  #section-content {
    background-color: #fff;
  }

  h2 {
    font-family: $heading-font;
    font-size: 2.074rem;
    line-height: 1.1;
    font-weight: $heading-font-normal;
    letter-spacing: -0.75px;
    color: $black;
    margin: 0;
    padding: 0;
    @media screen and (min-width: $b-md) {
      font-size: 2.441rem;
    }
  }
  
  h3 {
    font-family: $heading-font;
    font-size: 1.728rem;
    line-height: 1.1;
    font-weight: $heading-font-normal;
    letter-spacing: -0.75px;
    color: $black;
    margin: 0;
    padding: 0;
    @media screen and (min-width: $b-md) {
      font-size: 1.953rem;
    }
  }

}

.node-cbc-company-info-wrapper {
  @media screen and (min-width: $b-md) {
    position: absolute;
    right: -1rem;
    width: 50%;
    height: 500px;
  }
}

.node-cbc-company .node-cbc-company-header {
  margin: 1rem 0;
  border-left: 2px solid $capital-blue;
  padding: 0.5rem 0 0.5rem 1rem;
  @media screen and (min-width: $b-lg) {
    margin-left: -18px;
  }
}

.node-cbc-company .node-cbc-company-info,
.node-cbc-company .node-cbc-company-header {
  @media screen and (min-width: $b-md) {
    width: 50% !important;
  }
}

.node-cbc-company .node-cbc-company-header {
  @media screen and (min-width: $b-md) {
    margin: 3rem 0 1rem !important;
  }
}

.page-company-membership-chartered-building-companies-register #page-title,
.node-cbc-company .node-cbc-company-header__title-text {
  font-family: $heading-font !important;
  font-size: 2.488rem !important;
  line-height: 1.1;
  font-weight: $heading-font-normal;
  letter-spacing: -0.75px;
  color: $capital-blue;
  margin-bottom: 1rem;
  @media screen and (min-width: $b-md) {
    font-size: 3.052rem !important;
  }
}

.node-cbc-company .node-cbc-company-info .field-name-field-cbc-primary-contact {
  margin-top: 0 !important;
}

.node-cbc-company .node-cbc-company-info__address, 
.node-cbc-company .node-cbc-company-info__email,
.node-cbc-company .node-cbc-company-info__website, 
.node-cbc-company .node-cbc-company-info__telephone {
  @media screen and (min-width: $b-md) {
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

.node-cbc-company .node-cbc-company-info__label--website {
  @media screen and (min-width: $b-md) {
    margin-bottom: 0 !important;
  }
}

.node-cbc-company-label,
.node-cbc-company .node-cbc-company-info__label,
.view-cbc_companies_search-page h6, .view-cbc_companies_search-page label,
.view-cbc_companies_search-page .view-content .cbc-company-search__label {
  @include h6;
  font-size: 1rem !important;
  line-height: 1.2 !important;
  color: $black !important;
}

.node-cbc-company .node-cbc-company-info a {
  color: $capital-blue !important;
}

.node-cbc-company .node-cbc-company-info__label,
.view-cbc_companies_search-page .view-content .cbc-company-search__label {
  text-transform: uppercase;
}


.node-cbc-company .node-cbc-company-info__address__full-address,
.node-cbc-company .node-cbc-company-info__te .field-item,
.node-cbc-company p,
.node-cbc-company .node-cbc-company-work-examples .node-cbc-company-work-example-container .field-type-text-long,
.view-cbc_companies_search-page .view-content .cbc-company-search__row-container h6,
.node-cbc-company .node-cbc-company-info__website__link {
  font-size: 16px !important;
  font-family: $font;
  font-weight: normal !important;
  line-height: 1.6 !important;
  @media screen and (min-width: $b-md) {
    font-size: 18px !important;
  }
}

.view-cbc_companies_search-page h3 {
  font-family: $heading-font;
  font-size: 2.074rem !important;
  line-height: 1.1 !important;
  font-weight: $heading-font-normal !important;
  letter-spacing: -0.75px;
  color: $black;
  margin-bottom: 1rem;
  padding: 0;
  @media screen and (min-width: $b-md) {
    font-size: 2.441rem;
  }
}

// Examples
.node-cbc-company .node-cbc-company-work-examples .node-cbc-company-work-example-container {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.node-cbc-company .node-cbc-company-work-examples .node-cbc-company-work-example-container-header__image-container {
  margin-right: 0 !important;
}

.node-cbc-company .node-cbc-company-work-examples .node-cbc-company-work-example-container-header {
  background: transparent !important;
  padding: 1rem 0 !important;
  border-radius: 0 !important;
  height: auto !important;
  position: relative;

  &:after  {
    content: '';
    position: absolute;
    background-image: url(example-icon.svg);
    width: 17px;
    height: 20px;
    right: 0;
    top: 27px;
    transform: translate(-50%, -50%);
    transition: transform 0.3s ease-out;
  }

  &.accordion--active {
    &:after {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }

 }  


.node-cbc-company .node-cbc-company-work-examples .node-cbc-company-work-example-container-header__title {
  font-family: $heading-font !important;
  font-size: 1.2rem !important;
  line-height: 1.2 !important;
  font-weight: $heading-font-bold !important;
  color: $capital-blue !important;
  @media screen and (min-width: $b-md) {
    font-size: 1.25rem !important;
  }
}


.node-cbc-company .node-cbc-company-work-examples .node-cbc-company-work-example {
  padding: 0 !important;
  border-bottom: 2px solid $capital-blue;
  @media screen and (min-width: $b-md) {
    width: 50% !important;
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
}


.node-cbc-company .node-cbc-company-work-examples .node-cbc-company-work-example-container .field-type-text-long {
  margin: 0 0 1rem 0 !important;
}

.node-cbc-company-work-example-container-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.node-cbc-company .node-cbc-company-work-examples .node-cbc-company-work-example-container-header__image-container {
  max-width: 100% !important;
  margin-bottom: 16px;
}


