@import "../../lib.scss";

.header {
  font-family: $font;
  background: white;
  @media screen and (min-width: $b-sm) {
    margin-bottom: -62px;
    position: relative;
    z-index: 100;
    @media screen and (min-width: $b-md) {
      margin-bottom: -56px;
      position: absolute;
      right: 0;
    }
  }

  ul {
    display: flex;
    justify-content: space-evenly;
    flex: 1;
    align-items: stretch;
    margin: 0;
    @media screen and (min-width: $b-sm) {
      justify-content: flex-end;
    }
    li {
      color: $white;
      list-style: none;
      flex: 1;
      text-align: center;
      @media screen and (min-width: $b-sm) {
        flex: auto;
      }
      a {
        transition: all 0.3s ease;
        padding: 8px 10px;
        display: block;
        @media screen and (min-width: $b-sm) {
          padding: 18px 15px;
          height: 62px;
        }
        @media screen and (min-width: $b-md) {
          padding: 15px 15px;
          height: 56px;
        }
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
      &:nth-child(1) a {
        background: $beijing-pink;
        color: $black;
        &:hover,
        &:focus {
          background: darken($beijing-pink, 10%);
        }
      }
      &:nth-child(2) a {
        background: $sydney-blue;
        color: $black;
        &:hover,
        &:focus {
          background: darken($sydney-blue, 10%);
        }
      }
      &:nth-child(3) a {
        background: $capital-blue;
        color: $white;
        &:hover,
        &:focus {
          background: darken($capital-blue, 10%);
        }
      }
  
    }
  }
}
.site-alert {
  background-color: $grey;
  padding: 8px 16px;
  @media screen and (max-width: $b-sm) {
    min-height: 93px;
  }
  @media screen and (min-width: $b-sm) {
    padding: 15px 0;
  }
  &__container {
    @include container();
  }
  

  .site-alert-content{
    display: flex;
    flex-direction: column;
     text-align: center;
     color: $black;
     font-size: 17px;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.7;
     letter-spacing: 0.19px;
     vertical-align: center;
    @media screen and (min-width: $b-sm) {
     text-align: left;
     flex-direction: row;
     justify-content: space-between;
     height: 28.9px;
   }
   
   p {
    font-family: $font;
   }
   
   p:first-child{
     @media screen and (max-width: $b-sm) {
       margin-bottom: 5px;
     }
   }
    a{
      color:$black;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
