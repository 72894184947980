@import "../../lib.scss";

.text-block-sidebar {
  @include cols(1,1);
  @media screen and (min-width: $b-md) {
    @include cols(4, 12);
  }

  margin-bottom: 40px;
  @media screen and (min-width: $b-sm) {
    margin-bottom: 60px;
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  //Increase specificity
  & &__title {
    h3 {
      @include h3();
    }
  }

  &__description {
    padding-top: 15px;
    p {
      @include body-copy();
    }
  }

  &__button-container {
    display: inline-block;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
    a + a {
      display: block;
      margin-top: 15px;
      @media screen and (min-width: $b-sm) {
        margin-top: 25px;
      }
    }
  }

  &__button {
    position: relative;
    display: block;
    padding-right: 60px;
    cursor: pointer;
    overflow: hidden;
  }

  &__button-text {
    @include body-copy();
    font-size: 17px;
    display: inline-block;
    width: 100%;
    font-weight: $bold;
    color: $black;
    padding: 10px 10px 2px 0;
    border-bottom: 2px solid $light-blue;
    &:hover,
    &:focus {
      ~ span {
        &::before {
          transform: translateX(250%);
        }
        &::after {
          transform: translateX(0);
        }
      }
    }
  }

  &__button-arrow {
    overflow: hidden;
    width: 60px;
    height: 43px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: $light-blue;
    &::before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      top: 15px;
      left: 25px;
      background-image: url("./arrow.svg");
      background-repeat: no-repeat;
      transform: translateX(0);
      transition: transform 0.2s ease;
    }
    &::after {
      content: "View";
      position: absolute;
      font-size: 17px;
      color: $black;
      top: 8px;
      left: 9px;
      transform: translateX(-200%);
      transition: transform 0.2s ease;
      font-weight: $bold;
    }
    &:hover,
    &:focus {
      &::before {
        transform: translateX(250%);
      }
      &::after {
        transform: translateX(0);
      }
    }
  }
}
