@import "../../lib.scss";

.text-with-cta-block {
  background-color: #fafafa;

  &__container {
    @include container;
  }

  &__row {
    display: flex;
    flex-flow: row wrap;
    @include row();
  }

  &__text-area {
    @include cols(1, 1);
    @media screen and (min-width: $b-md) {
      @include cols(8, 12);
    }

    h2, h3, h4, h5, h6 {
      &:first-child {
        padding-top: 0px;
      }
    }

    margin-bottom: 25px;
    @media screen and (min-width: $b-sm) {
      margin-bottom: 45px;
    }
  }

  h2 {
    @include h2;
  }

  h3 {
    @include h3;
  }

  h4 {
    @include h4;
  }

  h5 {
    @include h5;
  }

  h6 {
    @include h6;
  }

  h2, h3, h4, h5, h6 {
    margin-bottom: 15px;
    padding-top: 15px;
  }

  p {
    @include body-copy;
    margin: 0 0 15px;
  }

  ul, ol {
    margin: 0 0 15px;
    padding: 0;
  }

  ul > li {
    list-style: none;
    @include body-copy;
    margin-bottom: 15px;

    &::before {
      display: inline-block;
      content: '';
      width: 12px;
      height: 12px;
      background-color: #00ccff;
      border-radius: 50%;
      margin: 0 15px 0 15px;
    }
  }

  ol {
    counter-reset: list-number-counter;
  }

  ol > li {
    list-style: none;
    @include body-copy;
    margin-bottom: 15px;
    counter-increment: list-number-counter;

    &::before {
      content: counter(list-number-counter);
      @include h5;
      margin: 0 15px 0 15px;
    }
  }

  a:link, a:visited, a:hover, a:active {
    color: $dark-blue;
    text-decoration: underline;
  }


  .call_to_action {
    @include body-copy();
    font-weight: $bold;
    position: relative;
    display: inline-block;
    padding: 7px 55px 7px 13px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: $blue;
    color: $white;
    box-shadow: $box-shadow;

    &:link, &:visited, &:hover, &:active {
      color: $white;
      text-decoration: none;
    }

    &::after {
      content: "";
      display: inline;
      background-image: url("./arrow-white.svg");
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      width: 20px;
      height: 20px;
      transition: right 0.3s ease;
    }

    &:hover,
    &:focus {
      &::after {
        right: 15px;
      }
    }
  }

  .faq{
    border-radius: 20px;
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    margin-bottom: 30px;
    border-bottom: 4px solid #004c99;
    min-height: 102px;
    padding: 20px;
    @media screen and (max-width: $b-sm) {
      width: 100%;
      float: none;
    }
    @media screen and (min-width: $b-lg) {
      width: 614px;
    }

    .question{
      background-color: #fff;
      color: #004c99;
      cursor: pointer;
      text-align: left;
      border: none;
      outline: none;
      transition: 0.4s;
      font-size: 22px;
      font-weight:bold;
      line-height: 1.4;
      position:relative;
      font-family: "Open Sans",sans-serif;
      width:100%;
      padding: 0 21px 0 0;
      }

    .question:after {
      content: '';
      background-image: url("./arrows.svg");
      background-repeat: no-repeat;
      font-size: 22px;
      color: #004c99;
      position:absolute;
      width:20px;
      height: 20px;
      right:0px;
      top:5px;
      transition: transform 1s;
    }

    .active:after {
      transform: rotate(180deg);
    }
    .answer{
      padding-top:10px;
      background-color: white;
      display: none;
      overflow: hidden;
      font-size: 17px;
      line-height: 1.7;
    }
  }

}