@import "../../lib.scss";

.news-card {
  @media screen and (min-width: $b-sm) {
    @include cols(4, 12);
  }

  &__news-icon {
    margin: 20px 0px 20px 15px;
    span {
      background-image: url("news_icon.svg");
      height: 80px;
      width: 80px;
      background-repeat: no-repeat;
      display: block;
      background-position: center;
      background-color: $light-blue;
      border-radius: 50%;
    }
  }

  &__background {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    box-shadow: $box-shadow;
    background-color: $white;
  }

  &__wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    &:hover, &:focus {
      & ~ .news-card__button-container {
        a {
          &::before {
            transform: translateX(250%);
          }

          &::after {
            transform: translateX(0);
          }
        }
      }
      & ~ .news-card__content h5.news-card__content__title {
        text-decoration: underline;
      }
    }
  }

  &__image {
    width: 100%;
    border-radius: 20px 20px 0 0;
  }

  &__content {
    margin: 0px 15px 10px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    &__title {
      @include h5();
      margin-bottom: 10px;
    }

    &__text, &__author, &__date {
      @include body-copy();
      color: $dark-brown;
      &--bold {
        font-weight: bold;
      }
    }
    &__text {
      margin-bottom: 20px;
    }
  }

  &__button-container {
  }

  &__button {
    float: right;
    background-color: $light-blue;
    margin-top: auto;
    width: 60px;
    height: 45px;
    border-radius: 20px 0;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    a {
      display: block;
      width: 100%;
      height: 100%;
      color: $black;
      line-height: 20px;


      &::before {
        content: "";
        position: absolute;
        width: 14px;
        height: 13px;
        top: 15px;
        left: 25px;
        background-image: url("./arrow.svg");
        transform: translateX(0);
        transition: transform 0.2s ease;
      }

      &::after {
        content: "View";
        position: absolute;
        font-size: 17px;
        top: 12px;
        left: 9px;
        transform: translateX(-200%);
        transition: transform 0.2s ease;
        font-weight: 700;
      }

      &:hover, &:focus {
        &::before {
          transform: translateX(250%);
        }

        &::after {
          transform: translateX(0);
        }
      }
    }

  }

}
