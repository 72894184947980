@import "../../lib.scss";

.insight-card {
  @media screen and (min-width: $b-sm) {
    @include cols(4, 12);
  }

  &__background {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    box-shadow: $box-shadow;
    background-color: $white;

    &:hover,
    &:focus {
      text-decoration: none;

      .insight-card {
        &__content {
          &__title {
            text-decoration: underline;
          }
        }

        &__button {
          text-decoration: none;

          &::before {
            transform: translateX(250%);
          }

          &::after {
            transform: translateX(0);
          }
        }
      }
    }
  }

  &__image {
    width: 100%;
    min-width: 1px; // IE fix
    min-height: 1px;
    border-radius: 20px 20px 0 0;
  }

  &__content {
    margin: 30px 20px 10px;

    .insight-card__image + & {
      margin: 20px 20px 10px;
    }

    &__title {
      @include h5();
      margin-bottom: 10px;
    }

    &__text {
      @include body-copy();
      color: $dark-brown;
    }
  }

  &__button-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row-reverse;
  }

  &__button {
    margin-top: auto;
    width: 60px;
    height: 43px;
    border-radius: 20px 0;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      top: 15px;
      left: 25px;
      background-image: url("./arrow.svg");
      background-repeat: no-repeat;
      transform: translateX(0);
      transition: transform 0.2s ease;
    }

    &::after {
      content: "View";
      position: absolute;
      font-size: 17px;
      font-weight: $bold;
      color: $black;
      top: 8px;
      left: 9px;
      transform: translateX(-200%);
      transition: transform 0.2s ease;
    }
  }
}
