@import "../../lib.scss";

.academy-promo-block {
  background-image: url(./academy-promo-background.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  padding: 40px 0 40px;
  margin-bottom: 40px;
  @media screen and (min-width: $b-sm) {
    padding: 60px 0 60px;
    margin-bottom: 60px;
  }

  &--show-logo {
    padding: 25px 0 40px;
    @media screen and (min-width: $b-sm) {
      padding: 40px 0 60px;
    }
  }

  &__logo-container {
    @include container();
  }

  &__logo-row {
    @media screen and (min-width: $b-sm) {
      @include row();
    }
  }

  &__logo {
    margin-bottom: 25px;
    @media screen and (min-width: $b-sm) {
      @include cols(2, 12);
      margin-bottom: 40px;
    }
    img {
      width: 120px;
      height: auto;
      @media screen and (min-width: $b-sm) {
        width: 100%;
      }
    }
  }

  &__container {
    @include container();
    &__inner {
      position: relative;
      background-color: $white;
      border-radius: 20px;
      box-shadow: $box-shadow;
      padding: 20px 15px 40px;
      border: $border;
      @media screen and (min-width: $b-sm) {
        padding: 60px 0 70px;
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $b-sm) {
      @include row();
      flex-flow: row wrap;
    }
  }

  &__column {
    @media screen and (min-width: $b-sm) {
      @include cols(8, 12);
      @include cols-push(2);
    }
  }

  &__title {
    h2 {
      @include h2();
      font-weight: $bold;
    }
  }

  &__description {
    margin-top: 15px;
    p {
      @include body-copy();
    }
  }

  &__button-wrapper {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $b-sm) {
      @include cols(4, 8);
    }
  }

  &__link {
    margin-top: auto;
  }

  &__button {
    position: relative;
    display: block;
    padding-right: 60px;
    cursor: pointer;
    overflow: hidden;
  }

  &__button-text {
    @include body-copy();
    font-size: 20px;
    display: inline-block;
    width: 100%;
    font-weight: $bold;
    padding: 10px 10px 2px 0;
    border-bottom: 2px solid $pale-blue;
    background-color: $white;
    color: $black;
    &:hover,
    &:focus {
      ~ span {
        &::before {
          transform: translateX(250%);
        }
        &::after {
          transform: translateX(0);
        }
      }
    }
  }

  &__button-arrow {
    overflow: hidden;
    width: 60px;
    height: 43px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: $pale-blue;
    &::before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      top: 15px;
      left: 25px;
      background-image: url("./pop-out.svg");
      background-repeat: no-repeat;
      transform: translateX(0);
      transition: transform 0.2s ease;
    }
    &::after {
      content: "View";
      position: absolute;
      font-size: 17px;
      color: $black;
      top: 10px;
      left: 9px;
      transform: translateX(-200%);
      transition: transform 0.2s ease;
      font-weight: $bold;
    }
    &:hover,
    &:focus {
      &::before {
        transform: translateX(250%);
      }
      &::after {
        transform: translateX(0);
      }
    }
  }
}
