@import "../../lib.scss";

.three-column-container {
  background-color: #fafafa;

  width: 100%;
  margin-bottom: 40px;
  @media screen and (min-width: $b-sm) {
    //We need the media query to override the one from container()
    margin-bottom: 40px;
  }

  &__container {
    @include container();
  }

  &__title {
    margin-bottom: 20px;
    text-align: center;
    h2 {
      @include h2();
    }
  }

  &__row {
    @media screen and (min-width: $b-sm) {
      @include row();
      display: flex;
      flex-flow: row wrap;
    }

    > * {
      margin-bottom: 20px;
    }
  }
}
