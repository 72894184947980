@import "../../lib.scss";

.image-block {
  background: #fafafa;

  margin-bottom: 40px;
  @media screen and (min-width: $b-sm) {
    margin-bottom: 60px;
  }

  &__container {
    @include container;
  }

  &__row {
    @media screen and (min-width: $b-sm) {
      @include row();
    }
  }

  &__content {
    @include cols(1, 1);
    @media screen and (min-width: $b-md) {
      @include cols(8, 12);
    }
  }

  &__image {
    width: 100%;
    display: block;
  }

}