@import "../../lib.scss";

//TODO: Add focus state to filters
@keyframes searchCardUp {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0%);
  }
}
@keyframes searchCardOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.search {
  ul,
  ol {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
  }

  &__header {
    padding-top: 18px;
    padding-bottom: 40px;
    background-color: $white;
    @media screen and (min-width: $b-sm) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    h1 {
      color: $blue;
      margin: 0 0 15px;
    }
  }

  &__filter {
    padding-top: 20px;
    padding-bottom: 32px;
    background-color: rgba(204, 214, 223, 0.3);
    @media screen and (min-width: $b-sm) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &__main {
    padding-top: 30px;
    padding-bottom: 60px;
    background-color: #fafafa;
  }

  &__container {
    @include container();
  }

  &__row {
    @media screen and (min-width: $b-sm) {
      @include row();
    }
  }

  &__search-box {
    @media screen and (min-width: $b-sm) {
      @include cols(8, 12);
    }
    form {
      position: relative;
      display: flex;
    }
    input {
      height: 50px;
      width: 100%;
      padding: 11px 24px;
      border-radius: 25px;
      border: solid 1px $blue;
      -webkit-appearance: none;
    }
    button {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 41px;
      height: 42px;
      border-radius: 50%;
      background-color: $blue;
      cursor: pointer;
      border: 0;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 17px;
      height: 17px;
      fill: $white;
      stroke-width: 2px;
    }
  }

  &__filter-list {
    @media screen and (min-width: $b-sm) {
      @include cols(12, 12);
    }
    display: flex;
    flex-direction: column;

    h5 {
      margin: 0 0 18px;
    }
    ul {
      display: flex;
      flex-flow: row wrap;
      margin: -6px;
    }
    li {
      position: relative;
      display: flex;
      border: 1px solid $blue;
      border-radius: 50px;
      background-color: $white;
      cursor: pointer;
      font-size: 15px;
      line-height: 1.93;
      letter-spacing: 0.16px;
      color: $body-color;
      margin: 6px;
    }
    a {
      display: inline-block;
      text-decoration: none;
      padding: 2px 34px 2px 14px;
      color: black;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 4px;
        border-radius: 50%;
        width: 23px;
        height: 23px;
        border: solid 1px $blue;
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: scale(0) translateY(-50%);
        transform-origin: 50% 50%;
        right: 8px;
        width: 15px;
        height: 16px;
        background-color: $blue;
        border-radius: 50%;
        transition: all 0.3s;
        transition-timing-function: cubic-bezier(0.32, 0.07, 0.51, 0.99);
        opacity: 0;
      }
      &:hover,
      &:focus {
        &::after {
          opacity: 0.5;
          transform: scale(1) translateY(-50%);
        }
      }
    }

    .ais-RefinementList-item--selected a::after {
      opacity: 1;
      transform: scale(1) translateY(-50%);
    }
  }

  &__results-list {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $b-sm) {
      @include cols(8, 12);
    }
  }

  &__stats {
    color: $dark-brown;
    margin-bottom: 20px;
    &__hits {
      font-weight: bold;
    }
  }

  &__hits {
    li + li {
      margin-top: 20px;
      @media screen and (min-width: $b-sm) {
        margin-top: 25px;
      }
    }
  }

  .search-card {
    opacity: 0;
    animation: searchCardUp 1s forwards cubic-bezier(0.3, 0.27, 0.07, 1.64),
      searchCardOpacity 1.5s forwards cubic-bezier(0.02, 0.01, 0.21, 1);
    position: relative;
    border-radius: 20px;
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.15);
    background-color: $white;
    &__meta {
      display: none;
    }
    &__meta + &__title {
      padding: 10px 20px 5px;
    }
    &__label {
      display: inline-block;
      padding: 11px 18px 13px 20px;
      border-top-left-radius: 20px;
      border-bottom-right-radius: 20px;
      font-size: 14px;
      letter-spacing: 0.15px;
      font-weight: bold;
      text-transform: capitalize;
      background-color: $light-blue;
      color: $black;
    }
    &__date {
      display: inline-block;
      margin-left: 20px;
      margin-top: 7px;
      font-size: 13px;
      line-height: 2.22;
      letter-spacing: 0.14px;
      color: $black;
    }
    &__title {
      padding: 20px 20px 5px;
      h5 {
        margin: 0;
      }
    }
    &__description {
      padding: 0 70px 20px 20px;
      p {
        margin: 0;
      }
    }
    &__button {
      background-color: $light-blue;
      width: 60px;
      height: 45px;
      border-radius: 20px 0;
      cursor: pointer;
      overflow: hidden;
      margin-left: auto;
      position: absolute;
      right: -1px;
      bottom: -1px;

      a {
        display: block;
        width: 100%;
        height: 100%;
        color: $black;
        line-height: 20px;

        &::before {
          content: "";
          position: absolute;
          width: 16px;
          height: 15px;
          top: 15px;
          left: 25px;
          background-image: url("./arrow.svg");
          background-repeat: no-repeat;
          transform: translateX(0);
          transition: transform 0.2s ease;
        }

        &::after {
          content: "View";
          position: absolute;
          font-size: 17px;
          top: 12px;
          left: 9px;
          transform: translateX(-200%);
          transition: transform 0.2s ease;
          font-weight: 700;
        }

        &:hover,
        &:focus {
          &::before {
            transform: translateX(250%);
          }

          &::after {
            transform: translateX(0);
          }
        }
      }
    }
    &--News {
      .search-card__meta {
        display: flex;
      }
    }

    &--Blog {
      .search-card__meta {
        display: flex;
      }
    }

    &--source-academy {
      .search-card__label {
        background-color: $yellow;
      }
      .search-card__button {
        background-color: $yellow;
        a::before {
          background-image: url("./pop-out.svg");
        }
      }
    }

    &--Course, &--Publication {
      .search-card__meta {
        display: flex;
      }
      .search-card__date {
        display: none;
      }
    }
  }

  &__pagination {
    font-size: 17px;
    font-weight: bold;
    color: $black;
    margin-top: 40px;
    ul {
      display: flex;
      justify-content: space-between;
    }
    li {
      position: relative;
    }
    a {
      text-align: center;
      line-height: 2;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      color: black;
    }
    .ais-Pagination-item {
      &--page {
        flex: 0 1 35px;
        height: 35px;
        border-radius: 50%;
        transition: background-color 0.2s ease;
        &:hover,
        &:focus {
          background-color: $light-blue;
        }
      }
      &--previousPage,
      &--nextPage {
        position: relative;
        min-width: 115px;
        span {
          text-align: center;
          line-height: 2;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
      &--previousPage {
        a::before,
        span::before {
          content: "";
          height: 14px;
          width: 8px;
          position: absolute;
          top: 10px;
          left: 0;
          background-image: url("./chevron.svg");
        }
      }
      &--nextPage {
        a::after,
        span::after {
          content: "";
          height: 14px;
          width: 8px;
          position: absolute;
          top: 10px;
          right: 15px;
          transform: rotate(180deg);
          background-image: url("./chevron.svg");
        }
      }

      &--selected {
        background-color: $light-blue;
      }
    }
  }

  mark {
    font-weight: bold;
    background-color: transparent !important;
  }
}
