@import "../../lib.scss";

.download-block {
  @include container;
  margin-bottom: 40px;
  @media screen and (min-width: $b-sm) {
    margin-bottom: 60px;
  }

  &__container {
    position: relative;
    background-color: $white;
    border-radius: 20px;
    box-shadow: $box-shadow;
    padding: 20px 15px;
    overflow: hidden;
    @media screen and (min-width: $b-sm) {
      padding: 60px 0;
    }
  }

  &__row {
    @media screen and (min-width: $b-sm) {
      @include row();
    }
  }

  &__column {
    @media screen and (min-width: $b-sm) {
      @include cols(8, 12);
      @include cols-push(1);
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    @media screen and (min-width: $b-sm) {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 38px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.4px;
    h2 {
      @include h2();
    }
  }

  &__description {
    margin-top: 15px;
    p {
      @include body-copy();
    }
  }

  &__button-row {
    @include row();
  }

  &__button-container {
    display: inline-block;
    flex-direction: column;
    margin-top: 25px;
    @include cols(1, 1);
    @media screen and (min-width: $b-sm) {
      @include cols(10, 12);
      @include cols-push(1);
    }
    a + a {
      display: block;
      margin-top: 15px;
      @media screen and (min-width: $b-sm) {
        margin-top: 25px;
      }
    }
  }

  &__button {
    position: relative;
    display: block;
    cursor: pointer;
    overflow: hidden;
    @media screen and (min-width: $b-sm) {
      padding-right: 150px;
    }
  }

  &__button-text {
    @include body-copy();
    font-size: 20px;
    display: inline-block;
    width: 100%;
    font-weight: $bold;
    color: $black;
    padding: 0 0 45px;
    background-color: $white;
    @media screen and (min-width: $b-sm) {
      padding: 10px 10px 2px 0;
      border-bottom: 2px solid $blue;
    }
    &:hover,
    &:focus {
      ~ span {
        &::before {
          transform: translateY(250%);
        }
      }
    }
  }

  &__button-arrow {
    overflow: hidden;
    width: 100%;
    height: 43px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: $blue;
    color: #fff;
    padding: 8px 0 0 20px;
    font-weight: $bold;
    @media screen and (min-width: $b-sm) {
      width: 150px;
    }
    &::before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      top: 14px;
      right: 20px;
      background-image: url("./arrow.svg");
      background-repeat: no-repeat;
      transform: translateY(0);
      transition: transform 0.2s ease;
    }
    &:hover,
    &:focus {
      &::before {
        transform: translateY(250%);
      }
    }
  }
}
