@import "../../lib.scss";

.block-ciob-general-search {
  input[type="text"] {
    background: #fff;
    border: solid 1px #004c99;
    border-radius: 20px;
    padding: 17px 32px 17px 16px;
    width: 100%;
    font-size: 14px;
    line-height: 2.06;
    font-weight: normal;
    color: #474149;
  }

  input[type="submit"] {
    height: 30px;
    width: 30px;
    position: absolute;
    right: 3px;
    top: 3px;
    background-color: #004c99;
    background-size: 14px 14px;
    border-radius: 50%;
    background-image: url("./search-icon.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
}
