@import "../../lib.scss";

.navigation {
  background-color: $white;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.05);
  position: relative;
  z-index: 3;


  &__container {
    @include container();
    display: flex;
    padding: 15px 15px;
    position: relative;
    @media screen and (min-width: $b-md) {
      padding: 0 15px;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $b-sm) {
      @include row();
      flex-flow: row wrap;
    }
  }

  &__logo {
    @include text-hide;
    background: url('./CIOB-logo.svg') no-repeat;
    width: 112px;
    height: 2rem;
    display: inline-block;
    @media screen and (min-width: $b-md) {
      width: 112px;
      margin: 0.75rem 0;
    }
  }

  &__back {
    padding: 0.25rem 0 0 1rem;
    color: $capital-blue;
    @media screen and (min-width: $b-sm) {
      padding: 1rem 0 0 1rem;
    }
  }

  &__inner-wrapper {
    display: none;
  }

  &__list {
    font-family: $font;
    font-weight: 600;
    display: none;
    margin-left: auto;

    @media screen and (min-width: $b-sm) {
      padding: 50px 0;
    }

    @media screen and (min-width: $b-md) {
      width: 100%;
      display: block;
      padding: 0;
      margin-left: 0;
      margin-top: auto;
      form {
        display: none;
      }
    }


    ul {
      display: inline-flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      padding: 0;
      @media screen and (min-width: $b-md) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    li {
      cursor: pointer;
      list-style: none;
    }

    li + li {

    }

    ul > li {
      position: relative;
      font-size: 20px;
      color: $blue;
      padding: 5px 15px 5px 15px;
      @media screen and (min-width: $b-md) {
        font-size: 18px;
        padding: 0 20px;
      }
    }

    ul > li::before,
    ul > li:hover::before {
      @media screen and (min-width: $b-md) {
        content: '';
        position: absolute;
        bottom: 0;
        height: 8px;
        background: $dark-brown;
        transform: scale3d(0, 1, 1) translateX(-50%);
        transition: all 0.1s;
        border-radius: 4px 4px 0 0;
        left: 20px;
        right: 20px;
      }
    }

    ul > li ul > li::before,
    ul > li ul > li:hover::before,
    ul > li ul > li ul > li::before,
    ul > li ul > li ul > li:hover::before {
      height: 0;
    }

    ul > li.active {
      @media screen and (max-width: $b-md - 1) {
        background: $dark-brown;
        border-radius: 20px;
        color: white;
        padding: 15px 0 0 0;
      }
    }

    ul > li.active::before,
    ul > li:hover::before {
      @media screen and (min-width: $b-md) {
        transform: scale3d(1, 1, 1);
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 0.3s;
      }
    }

    ul > li > span {
      display: block;
      position: relative;
      font-weight: bold;

      &:before {
        content: '';
        position: absolute;
        right: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: $dark-blue url("./m-arrow-down.svg") no-repeat 50% 50%;
      }
      @media screen and (min-width: $b-md) {
        &:before {
          display: none;
        }

        display: inline-block;
        padding: 20px 0 20px 0;
      }
    }

    ul > li.active > span {
      border-bottom: 1px solid rgba(255,255,255,0.5);
      margin: 0 15px 10px 15px;
      padding-bottom: 10px;
      &:before {
        background: $white url("./m-arrow-up.svg") no-repeat 50% 50%;
      }
      @media screen and (min-width: $b-md) {
        border-bottom: none;
        margin: 0;
        padding: 20px 0 20px 0;
      }
    }

    ul > li > a {
      display: inline-block;
      @media screen and (min-width: $b-md) {
        padding: 10px 16px;
      }

      @media screen and (min-width: $b-lg) {
        padding: 10px 32px;
      }
      &.navigation__link--show-academy-logo {
        position: relative;
        padding-top: 50px;
        &:before {
          content: '';
          display: block;
          background: url("./ciob-academy-logo.svg");
          width: 163px;
          height: 40px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      &.navigation__link--external {
        position: relative;
        padding-right: 20px;
        &:after {
          content: '';
          display: block;
          background: url("./external-icon.svg");
          width: 15px;
          height: 15px;
          position: absolute;
          top: 6px;
          right: 0;
        }
      }
      &.navigation__link--show-academy-logo.navigation__link--external:after {
        top: 55px;
      }
    }

    ul > li > ul {
      display: none;
      background-color: $dark-brown;
      color: white;
      border-radius: 20px;
      font-size: 16px;
      p {
        margin: 0;
        font-weight: 300;
        font-size: 13px;
      }
      @media screen and (min-width: $b-md) {
        margin-top: 0;
        flex-direction: column;
        border-radius: 0 0 20px 20px;
      }
    }

    ul > li > ul > li > ul {
      display: block;
    }

    ul > li.active > ul {
      display: flex;
      padding: 0 15px;
      @media screen and (min-width: $b-md) {
        position: absolute;
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
        min-width: 355px;
        padding: 0 15px;
      }
    }

    ul > li.active > ul >li > ul {
      padding: 0 30px;
    }

    ul > li > ul > li {
      padding: 10px 0;
      font-size: 16px;
      border-bottom: 1px solid rgba(255,255,255,0.5);
      font-weight: 600;
      margin-bottom: 10px;
    }

    ul > li > ul > li:last-child {
      padding: 10px 0 10px;
      margin: 0;
      border-bottom: none;
    }

    ul > li > ul > li > a {
      padding: 0;
      color: $white;
      display: block;
      font-weight: bold;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    ul > li > ul > li > ul > li > a {
      font-weight: normal;
    }

    ul > li > ul > li > ul > li {
      padding: 10px 0 10px;
      border-bottom: none;
      font-weight: 300;
      margin-bottom: 0;
    }

    ul > li > ul > li > ul > li:last-child {
      padding-bottom: 0;
    }


    &--open {
      position: absolute;
      right: 0;
      top: 60px;
      display: block;
      background-color: #fff;
      padding: 0 0 15px;
      width: 100%;

      @media screen and (max-width: $b-md - 1) {
        -webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.05);
        -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.05);
        box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.05);
        border-radius: 0 0 20px 20px;
      }

    }
  }

  &__mobile-nav {
    // display: flex;
    // flex-direction: column;
    // cursor: pointer;
    // font: inherit;
    // color: inherit;
    // text-transform: none;
    // border: none;
    // overflow: visible;
    // box-shadow: none;
    // width: 43px;
    // height: 22px;
    // background: url("./nav-button.svg") no-repeat;
    // margin: 5px 0 0 auto;
    // @media screen and (min-width: $b-md) {
    //   display: none;
    // }


    // &--active {
    //   background: url("./nav-close.svg") no-repeat 18px 0;
    // }
      display: none;
  }

  .active-trail {
    font-weight: bold;
    color: $blue;
    @media screen and (min-width: $b-md) {
      font-weight: normal;
      color: white;
      background-repeat: no-repeat;
      background-position: center;
    }
    &::before {
      @media screen and (min-width: $b-md) {
        transform: scale3d(1, 1, 1);
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 0.3s;
      }
    }
    > ul > li.active-trail {
      color: white;
      font-weight: normal;
    }
  }

  /* TODO: Note to future Rowan. This should be fixed */
  .contextual {
    display: none;
  }

  &__mobile-search {
    margin: 0 15px;
    &__box {
      height: 36px;
      width: 100%;
      z-index: 1;
      -webkit-appearance: none;
      border: 1px $blue solid;
      border-radius: 18px;
      padding-left: 15px;
      position: absolute;
      left: 0;
    }

    form {
      position: relative;
      height: 36px;
      margin: 5px 0 15px;
    }

    label {
      @include text-hide();
    }

    &--submit {
      position: absolute;
      top: 3px;
      right: 2px;
      z-index: 2;
      height: 30px;
      width: 30px;
      background: url("./search.svg") no-repeat;
      border: none;
      @include text-hide();
    }
    @media screen and (min-width: $b-md) {
      display: none;
    }
  }


  &__search {
    display: flex;
    form {
      display: none;
      @media screen and (min-width: $b-md) {
        display: flex;
        width: 1px;
      }
    }

    &__toggle {
      display: none;
      @media screen and (min-width: $b-md) {
        display: block;
        @include text-hide();
        align-self: center;
        height: 30px;
        width: 30px;
        background: url("./search.svg") no-repeat;
        cursor: pointer;
      }
      @media screen and (min-width: $b-md) {
        height: 30px;
        width: 30px;
        margin-top: 18px;
      }
    }


    label {
      @include text-hide();
    }

    &__box {
      display: none;
      @media screen and (min-width: $b-md) {
        display: block;
        height: 36px;
        z-index: 1;
        -webkit-appearance: none;
        border: 1px $blue solid;
        border-radius: 18px;
        padding-left: 15px;
        transform: translateY(-50%);
        width: 100%;
        transition: clip 0.3s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        clip: rect(auto, auto, auto, 100vw);
        position: absolute;
        bottom: 0;
        left: 0px;
        top: 53px;
      }
      /* Hide the clear button from seach inputs */
      &::-ms-clear {
        display: none;
      }
      &::-webkit-search-cancel-button {
        display: none;
      }
    }

    &--open {
      form {
        @media screen and (max-width: $b-md - 1) {
          display: flex;
          position: absolute;
          top: 70px;
          left: 15px;
          width: -webkit-calc(100% - 15px);
          width: calc(100% - 15px);
        }
      }
    }


    &--open &__box {
      clip: rect(auto, auto, auto, 0);
    }

    &--close {
      display: none;
      @media screen and (min-width: $b-md) {
        @include text-hide();
        position: absolute;
        width: 30px;
        height: 30px;
        display: block;
        margin-right: 10px;
        z-index: 2;
        opacity: 0;
        pointer-events: none;
        cursor: pointer;
        top:38px;
        right: -7px;
        background: url("./close.svg") no-repeat;
      }
    }
    &--open &--close {
      @media screen and (min-width: $b-md) {
        display: block;
        opacity: 1;
        pointer-events: visible;
        transition: opacity 0.5s ease;
        transition-delay: 0.3s;
      }
    }
    &--submit {
      @include text-hide();
      &:focus {
        outline: none;
      }
    }
  }

}



