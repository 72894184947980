// Screen sizes
$b-sm: 768px;
$b-md: 992px;
$b-lg: 1200px;

// Colours
$white: #ffffff;
$light-blue: #00ccff;
$yellow: #fdbb30;
$blue: #004c99;
$pale-blue: #6898c7;
$red: #e31b23;
$orange: #df7a1c;
$green: #c1d82f;
$dark-green: #00703c;
$grey: #ccd6df;
$dark-brown: #474149;
$black: #000000;
$body-color: #474149;
$icon-blue: #00b8f1;
$dark-blue: #0072d6;

$box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.15);
$border: 1px solid #f3efef;

// new colours
$capital-blue: #1900b6;
$black: #000000;
$white: #ffffff;
$grey: #cfd9e2;
$light-blue: #e1f7ff;

$sydney-blue: #1ab7ea;
$new-york-green: #c1d72d;
$london-turquoise: #19ce8d;
$cape-yellow: #fdb930;
$beijing-pink: #fca9a9;
$singapore-cherry: #e31c79;
$dubai-sand: #eaba8b;

// Font
// $font: "Open Sans", sans-serif;
// $bold: 700;

// Typograohy
$heading-font: 'Montserrat', sans-serif;

$heading-font-normal: 500;
$heading-font-bold: 600;

$font: 'Inter', sans-serif;

$font-normal: 400;
$font-semi: 600;
$bold: 700;


// Grid
$max-width: 1200px;
$container: 18px;
$mobile-gutter: 16px;
$gutter: 18px;

$page-gutter: 18px;
$g-xs: 10px;
$g-md: 20px;

//Animation
$hero-fade-in-time: 0.6s;
$hero-fade-in-delay: 0.6s;
$hero-promo-fade-up-time: 0.6s;
$hero-promo-fade-up-delay: 1s;

@mixin container() {
  width: 100%;
  padding-left: $container;
  padding-right: $container;
  @media screen and (min-width: $b-sm) {
    max-width: $max-width;
    margin: 0 auto;
  }
}

@mixin cols-pad() {
  padding-left: $g-xs / 2;
  padding-right: $g-xs / 2;

  @media screen and (min-width: $b-sm) {
    padding-left: $g-md / 2;
    padding-right: $g-md / 2;
  }
}

@mixin row($mobile-gutter: $page-gutter) {
  margin-left: -$g-xs / 2;
  margin-right: -$g-xs / 2;

  @media screen and (min-width: $b-sm) {
    margin-left: -$g-md / 2;
    margin-right: -$g-md / 2;
  }

  > * {
    // Having this in here like this is actually a bit annoying as I think it
    // makes grid nesting a bit more tricky than it was before.
    padding-left: $mobile-gutter;
    padding-right: $mobile-gutter;
    @include cols-pad();
  }
}

@mixin cols($n, $basis: 12) {
  width: (100% / $basis) * $n;
  max-width: (100% / $basis) * $n;
  flex-basis: (100% / $basis) * $n;
}

@mixin cols-push($n, $basis: 12) {
  margin-left: (100% / $basis) * $n;
}

// Font Mixin
@mixin h1 {
  font-family: $heading-font;
  font-size: 2.488rem;
  line-height: 1.1;
  font-weight: $heading-font-normal;
  letter-spacing: -0.75px;
  color: $black;
  margin: 0;
  padding: 0;
  @media screen and (min-width: $b-md) {
    font-size: 3.052rem;
  }
}

@mixin h2 {
  font-family: $heading-font;
  font-size: 2.074rem;
  line-height: 1.1;
  font-weight: $heading-font-normal;
  letter-spacing: -0.75px;
  color: $black;
  margin: 0;
  padding: 0;
  @media screen and (min-width: $b-md) {
    font-size: 2.441rem;
  }
}

@mixin h3 {
  font-family: $heading-font;
  font-size: 1.728rem;
  line-height: 1.1;
  font-weight: $heading-font-normal;
  letter-spacing: -0.75px;
  color: $black;
  margin: 0;
  padding: 0;
  @media screen and (min-width: $b-md) {
    font-size: 1.953rem;
  }
}

@mixin h4 {
  font-family: $heading-font;
  font-size: 1.44rem;
  line-height: 1.1;
  font-weight: $heading-font-normal;
  letter-spacing: -0.75px;
  color: $black;
  margin: 0;
  padding: 0;
  @media screen and (min-width: $b-md) {
    font-size: 1.563rem;
  }
}

@mixin h5 {
  font-family: $heading-font;
  font-size: 1.2rem;
  line-height: 1.2;
  font-weight: $heading-font-bold;
  color: $black;
  margin: 0;
  padding: 0;
  @media screen and (min-width: $b-md) {
    font-size: 1.25rem;
  }
}

@mixin h6 {
  font-family: $heading-font;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: $heading-font-bold;
  color: $black;
  margin: 0;
  padding: 0;
}

@mixin body-copy {
  font-family: $font;
  font-size: 1rem;
  line-height: 1.6;
  color: $black;
  margin: 0;
}

@mixin intro-copy {
  font-size: 1.2rem;
  @media screen and (min-width: $b-md) {
    font-size: 1.25rem;
  }
}

@mixin small-copy {
  font-size: 1rem;
  @media screen and (min-width: $b-md) {
    font-size: 0.89rem;
  }
}

@mixin text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
