@import "../../lib.scss";

@keyframes promoFadeUp {
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(-115px);
  }
}

.promo-block {
  @include container;
  margin-bottom: 40px;
  @media screen and (min-width: $b-sm) {
    margin-bottom: 60px;
  }
  /* Disable pointer events on promo-block to allow click-through */
  pointer-events: none;

  /* Only fade in if marked as first */
  .g--first-animate > & {
    margin-bottom: 40px;
    @media screen and (min-width: $b-sm) {
      opacity: 0;
      margin-bottom: -55px;
      .g--load-transition & {
        animation: promoFadeUp $hero-promo-fade-up-time;
        animation-delay: $hero-promo-fade-up-delay;
        animation-fill-mode: forwards;
      }
    }
  }

  &__container {
    /* Forcefully re-enable pointer-events */
    pointer-events: auto;
    position: relative;
    background-color: $white;
    border-radius: 20px;
    box-shadow: $box-shadow;
    padding: 20px 15px;
    overflow: hidden;
    @media screen and (min-width: $b-sm) {
      padding: 60px 0 70px;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      height: 130px;
      width: 100%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      background-image: url(./promo.png);
      @media screen and (min-width: $b-sm) {
        width: 525px;
        height: 265px;
        background-size: cover;
      }
    }
  }

  &__row {
    @media screen and (min-width: $b-sm) {
      @include row();
    }
  }

  &__column {
    @media screen and (min-width: $b-sm) {
      @include cols(8, 12);
      @include cols-push(1);
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    margin-bottom: 130px;
    @media screen and (min-width: $b-sm) {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 38px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.4px;
    h2 {
      @include h2();
    }
  }

  &__description {
    margin-top: 15px;
    p {
      @include body-copy();
    }
  }

  &__button-row {
    @include row();
  }

  &__button-container {
    display: inline-block;
    flex-direction: column;
    margin-top: 25px;
    @include cols(1, 1);
    @media screen and (min-width: $b-sm) {
      @include cols(5, 8);
    }
    a + a {
      display: block;
      margin-top: 15px;
      @media screen and (min-width: $b-sm) {
        margin-top: 25px;
      }
    }
  }

  &__button {
    position: relative;
    display: block;
    padding-right: 60px;
    cursor: pointer;
    overflow: hidden;
  }

  &__button-text {
    @include body-copy();
    font-size: 20px;
    display: inline-block;
    width: 100%;
    font-weight: $bold;
    color: $black;
    padding: 10px 10px 2px 0;
    border-bottom: 2px solid $pale-blue;
    background-color: $white;
    &:hover,
    &:focus {
      ~ span {
        &::before {
          transform: translateX(250%);
        }
        &::after {
          transform: translateX(0);
        }
      }
    }
  }

  &__button-arrow {
    overflow: hidden;
    width: 60px;
    height: 43px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: $pale-blue;
    &::before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      top: 15px;
      left: 25px;
      background-image: url("./arrow.svg");
      background-repeat: no-repeat;
      transform: translateX(0);
      transition: transform 0.2s ease;
    }
    &::after {
      content: "View";
      position: absolute;
      font-size: 17px;
      color: $black;
      top: 8px;
      left: 9px;
      transform: translateX(-200%);
      transition: transform 0.2s ease;
      font-weight: $bold;
    }
    &:hover,
    &:focus {
      &::before {
        transform: translateX(250%);
      }
      &::after {
        transform: translateX(0);
      }
    }
  }
}
