@import "../../lib.scss";

.footer {
  font-family: $font;
  background-color: $capital-blue;
  padding: 2rem 0;
  @media screen and (min-width: $b-sm) {
    padding: 3rem 0;
  }
  &__container {
    @include container();
  }

  &__row {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $b-sm) {
      @include row();
      flex-flow: row wrap;
    }
  }

  &__column {
    @media screen and (min-width: $b-sm) {
      @include cols(3, 12);
    }
  }

  &__column + &__column {
    @media screen and (min-width: $b-sm) {
      @include cols-push(1);
    }
  }

  &__list-wrapper {
    border-left: 1px solid $white;
    padding-left: 1rem;
    &--mobile {
      @media screen and (min-width: $b-sm) {
        display: none;
      }
    }
  }

  &__title {
    margin-bottom: 10px;
    h4 {
      @include h4;
      color: $white;
      margin-bottom: 3px;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    margin-bottom: 40px;
  }

  li + li {
    margin-top: 15px;
  }

  li {
    color: $white;
    list-style: none;
    a {
      transition: all 0.3s ease;
      color: $white;
      &:hover,
      &:focus {
        box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 1);
        text-decoration: none;
      }
    }
  }

  &__contact li {
    font-weight: normal;
  }

  &__social {
    display: none;
    @media screen and (min-width: $b-sm) {
      display: block;
    }
    &__title {
      margin-bottom: 1rem;
      h4 {
        @include h4();
        color: $white;
        margin-bottom: 3px;
      }
    }
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0;
    }
    li + li {
      margin: 0 0 0 20px;
    }
    li a {
      @include text-hide;
      &:hover,
      &:focus {
        box-shadow: none;
      }
      svg {
        fill: $white;
        transition: fill 0.3s ease;
      }
      &:hover,
      &:focus {
        svg {
          fill: $sydney-blue;
          transition: fill 0.3s ease;
        }
      }
    }

    .svg-inline--fa {
      width: 28px;
      height: 28px;
      transition: color 0.3s ease;
      &:hover,
      &:focus {
        color: $icon-blue;
      }

    }

    &__list-wrapper--mobile {
      margin-bottom: 2rem;
    }

    &--mobile {
      display: block;
      margin-bottom: 0;
      @media screen and (min-width: $b-sm) {
        display: none;
      }
    }
  }

  &__lower {
    display: block;
    @include small-copy();
    color: $white;
    span + span {
      margin-top: 15px;
    }
  }

  &__company-info {
    @include small-copy;
    margin-top: 2rem;
    @media screen and (min-width: $b-sm) {
      margin-top: 0;
    }
  }
}
