@import "../../lib.scss";
$border-radius: 20px;

.news-block {
  color: $black;
  background: #fafafa;

  &__container {
    @include container;
  }

  &__row {
    @media screen and (min-width: $b-sm) {
      @include row();
    }
  }

  &__column {
    @media screen and (min-width: $b-sm) {
      @include cols(12, 12);
    }
  }

  &__title {
    text-align: center;
    margin: 0px 0 20px;
    @media screen and (min-width: $b-sm) {
      margin: 0px 0 40px;
    }
    h1 {
      @include h1;
    }
    h2 {
      @include h2;
    }
  }
  .featured-news {
    position: relative;
    display: flex;
    flex-flow: column;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    color: $black;
    overflow: hidden;
    margin-bottom: 20px;
    @media screen and (min-width: $b-sm) {
      flex-flow: row-reverse;
      flex: 1 0 100%;
      margin-bottom: 40px;
    }
    &__wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      &:hover,
      &:focus {
        & ~ .featured-news__button {
          span {
            &::before {
              opacity: 1;
              transform: translate(0, 0);
            }
            &::after {
              opacity: 0;
              transform: translate(100%, 0);
            }
          }
        }
        & ~ .featured-news__content > .featured-news__content-title > h5 {
          text-decoration: underline;
        }
      }
    }
    &__image-container {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 100%;

      border-top-right-radius: $border-radius;
      border-top-left-radius: $border-radius;
      @media screen and (min-width: $b-sm) {
        flex: 2 0 40%;
        background-size: cover;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        border-top-left-radius: 0px;
        height: auto;
      }
      img {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
        max-width: 100%;
        height: auto;
        display: block;
        width: 100%;
        @media screen and (min-width: $b-sm) {
          border-top-left-radius: 0px;
          border-bottom-right-radius: $border-radius;
          display: none;
        }
      }
    }
    &__content {
      padding: 20px 15px 10px 15px;
      @media screen and (min-width: $b-sm) {
        margin-top: 43px;
        padding: 20px 23px 40px 20px;
      }
      &-title {
        margin-bottom: 7px;
        @media screen and (min-width: $b-sm) {
          margin-bottom: 10px;
        }
        h5 {
          @include h5;
        }
      }
      &-teaser {
        @include body-copy;
        @media screen and (min-width: $b-sm) {
          display: block;
        }
      }
    }

    &__button {
      display: block;
      @media screen and (min-width: $b-sm) {
        position: absolute;
        bottom: 0px;
        right: 0px;
      }
      span {
        overflow: hidden;
        position: relative;
        display: block;
        width: 60px;
        height: 43px;
        float: right;
        border-top-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;

        background-color: $light-blue;
        &:hover,
        &:focus {
          cursor: pointer;
          &::before {
            opacity: 1;
            transform: translate(0, 0);
          }
          &::after {
            opacity: 0;
            transform: translate(100%, 0);
          }
        }
        &::before {
          content: "View";
          position: absolute;
          top: 1px;
          left: 0px;
          width: 100%;
          opacity: 0;
          transform: translate(-100%, 0);
          transition: all 0.3s ease-in-out;

          font-size: 17px;
          font-weight: 700;
          color: $black;
        }
        &::after {
          content: "";
          position: absolute;
          top: 1px;
          left: 0px;
          width: 100%;
          transition: all 0.3s ease-in-out;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url(./arrow.svg);
        }
        &::before,
        &::after {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
    }

    &__tag {
      position: absolute;
      top: 0px;
      left: 0px;
      padding: 11px 18px 13px 20px;
      border-top-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;

      font-size: 14px;
      letter-spacing: 0.15px;
      font-weight: bold;
      background-color: $light-blue;
    }
  }
}
