@import "./lib.scss";

html {
  box-sizing: border-box;
  font-size: 16px;
  @media screen and (min-width: $b-md) {
    font-size: 18px;
  }
}

body {
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: $white;
  > * {
    flex: 0 0 auto; /* IE */
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

input,
button,
textarea {
  /* This needs to be set based on the body font. Leaving this here for now until we import the default css incase this is needed. */
  /* font-family: var(--font-body); */
  font-size: 1em;
  border-radius: 0; /*Safari mobile.*/
}
