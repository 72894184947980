@import "../../lib.scss";

@keyframes heroFadeIn {
  from {
    opacity: 0;
    transform: translateX(-150%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes heroFadeUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero {
  position: relative;

  /**
   * When parallax is enabled, (hero has an image), the space between the hero and the next component needs to be owned by the next component.
   * This is necessary because some components need to apply a background over that area.
   */
  + .g--first-animate > *,
  + :not(.g--first-animate) {
    padding-top: 20px;
    position: relative;
    z-index: 2;
    @media screen and (min-width: $b-sm) {
      padding-top: 60px;
    }
  }

  &__media {
    overflow: hidden;
    height: 0;
    padding-top: 56.25%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media screen and (min-width: $b-sm) {
      height: 542px;
      padding: 0;
    }
  }

  &--no-image &__media {
    background-color: $white;

    padding-top: 0;
    @media screen and (min-width: $b-sm) {
      height: auto;
    }
  }

  &__container {
    @include container();
    @media screen and (min-width: $b-sm) {
      top: 85px;
    }

    &--desktop {
      display: none;
      @media screen and (min-width: $b-sm) {
        display: block;
      }
    }
    &--mobile {
      display: block;
      margin-top: -40px;
      @media screen and (min-width: $b-sm) {
        display: none;
      }
    }
  }

  &--no-image &__container--mobile {
    background-color: $white;
    margin-top: 0px;
    padding-bottom: 40px;

  }

  &__row {
    @media screen and (min-width: $b-sm) {
      @include row();
    }
  }

  &__content {
    @media screen and (min-width: $b-sm) {
      padding-top: 90px;
      margin-left: 10px;
    }
  }

  &--no-image &__content {
    @media screen and(min-width: $b-sm) {
      padding-top: 60px;
      padding-bottom: 60px;
      padding-left: 0px;
    }
  }

  &__title {
    opacity: 0;
    .g--load-transition & {
      animation: heroFadeIn $hero-fade-in-time;
      animation-fill-mode: forwards;
    }

    @media screen and (min-width: $b-md) {
      @include cols(8, 12);
      padding-left: 0;
      padding-right: 0;
    }
    h1 {
      @include h1();
      color: $white;
    }
  }

  &__highlight {
    display: block;
    width: 100%;
    background: $blue;
    color: white;
    line-height: 1.2;
    padding: 10px;


    @media screen and (min-width: $b-sm) {
      display: inline;
      line-height: 1.4;
      padding: 1px;
      padding-left: 0;
      padding-right: 0;
      box-decoration-break: clone;
      box-shadow: 10px 0 0 $blue, -10px 0 0 $blue;
    }
  }

  &--no-image &__highlight {
    background: none;
    box-shadow: none;
    color: $blue;
    padding-left: 0px;
  }

  &__text {
    color: $white;
    margin-top: 10px;
    padding: 10px;
    background-color: $blue;
    opacity: 0;

    .g--load-transition & {
      animation: heroFadeIn $hero-fade-in-time;
      animation-fill-mode: forwards;
      animation-delay: $hero-fade-in-delay;
    }

    @media screen and (min-width: $b-sm) {
      @include cols(7, 12);
      padding: 3px;
      padding-left: 0;
      padding-right: 0;
      box-shadow: 10px 0 0 $blue, -10px 0 0 $blue;
    }
    p {
      @include body-copy();
      font-weight: 600;
      letter-spacing: 0.19px;
      color: $white;
    }
  }

  &--no-image &__text {
    background: none;
    box-shadow: none;
    color: $blue;
    padding: 0px;

    p {
      color: $blue;
    }

  }

  &__cta {
    opacity: 0;

    .g--load-transition & {
      animation: heroFadeUp $hero-promo-fade-up-time;
      animation-fill-mode: forwards;
      animation-delay: $hero-promo-fade-up-delay;
    }

    @media screen and (min-width: $b-sm) {
      margin-left: -10px;
    }

    a {
      @include body-copy();
      font-weight: $bold;
      position: relative;
      display: inline-block;
      margin-top: 20px;
      padding: 7px 55px 7px 13px;
      border-top-left-radius: 20px;
      border-bottom-right-radius: 20px;
      background-color: $blue;
      color: $white;
      box-shadow: $box-shadow;
      @media screen and (min-width: $b-sm) {
        background-color: $white;
        color: $blue;
      }
      &::after {
        content: "";
        display: inline;
        background-image: url("./arrow-white.svg");
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        width: 20px;
        height: 20px;
        transition: right 0.3s ease;
        @media screen and (min-width: $b-sm) {
          background-image: url("./arrow-blue.svg");
        }
      }
      &:hover,
      &:focus {
        &::after {
          right: 15px;
        }
      }
    }
  }

  &--no-image &__cta {
    margin-left: 0px;
    a {
      background-color: $blue;
      color: $white;
      margin-top: 10px;
    }

    a::after {
      background-image: url("./arrow-white.svg");
      @media screen and (min-width: $b-sm) {
        background-image: url("./arrow-white.svg");
      }
    }
  }
}
