@import "../../lib.scss";


.embedded-video-block {
  background-color: #fafafa;

  margin-bottom: 40px;
  @media screen and (min-width: $b-sm) {
    margin-bottom: 60px;
  }

  &__container {
    @include container();
  }

  &__row {
    @media screen and (min-width: $b-sm) {
      @include row();
    }
  }

  &__content {
    @include cols(1, 1);
    @media screen and (min-width: $b-md) {
      @include cols(8, 12);
    }
  }

  &__iframe-wrapper {
    padding-top: calc(9 / 16 * 100%); // 16/9 aspect ratio
    position: relative;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

}